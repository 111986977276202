import { post } from '@/utils/request';
export default {
  name: 'FinanceDialog',
  data() {
    return {
      readonly: false,
      title: '',
      action: '/finance_detail/edit',
      form: {}
    };
  },
  methods: {
    setForm({
      id,
      money,
      file,
      status,
      ware
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        money: {
          label: '发票金额',
          value: money,
          formType: 'number'
        },
        file: {
          label: '凭证文件',
          value: file,
          formType: 'upload',
          attrs: {
            accept: '.pdf,.jpg,.png,.jpeg,.gif'
          }
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    open(data = {}, readonly = false) {
      this.$refs.dialogForm.visible = true;
      this.readonly = readonly;
      this.$nextTick(() => this.setForm(data));
    }
  }
};