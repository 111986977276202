import { cloneDeep } from 'lodash';
import TableForm from '@/components/TableForm/index.vue';
export default {
  components: {
    TableForm
  },
  name: 'ConfigSupplier',
  props: {
    name: {
      type: String,
      required: true
    },
    group: {
      type: Number,
      default: () => {
        return 2;
      }
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      operates: {
        del: {
          show: true,
          url: 'SalesSettingCompany/delete'
        },
        look: false,
        edit: true
      },
      actions: {
        index: [],
        sort: (row, newIndex, oldIndex) => {
          this.actions.index.splice(newIndex, 0, ...this.actions.index.splice(oldIndex, 1));
          this.actions.index = JSON.parse(JSON.stringify(this.actions.index));
          this.refresh();
        },
        columns: [{
          v: 'title',
          label: '公司名称',
          search: false,
          sort: false
        }, {
          v: 'mobile',
          label: '联系电话',
          search: false,
          sort: false
        }, {
          v: 'address',
          label: '地址',
          search: false,
          sort: false
        }, {
          v: 'account',
          label: '结算单位',
          search: false,
          sort: false
        }, {
          v: 'remark',
          label: '备注',
          search: false,
          sort: false
        }, {
          v: 'status',
          label: '状态',
          render: 'status',
          searchType: false,
          sort: false
        }]
      },
      dialogFormVisible: false,
      formAction: 'SalesSettingCompany/add',
      form: {},
      index: -1,
      tableFormColumns: [{
        prop: 'type',
        show: false,
        defaultValue: 2
      }, {
        prop: 'account_name',
        label: '单位名称'
      }, {
        prop: 'account',
        label: '结算账户'
      }]
    };
  },
  watch: {
    name(val, old) {
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.$w_fun.post('SalesSettingCompany/index', {
        type: this.name
      }).then(response => {
        if (response.data) {
          this.actions.index = response.data.detail ? response.data.detail.value : response.data.list.data;
        }
        this.listLoading = false;
        this.refresh();
      });
    },
    refresh() {
      this.$refs.wTable && this.$refs.wTable.onRefresh();
    },
    setForm({
      id = undefined,
      mobile,
      title,
      short_title,
      address,
      status,
      remark,
      account = [],
      person_id
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '公司名称',
          value: title,
          max: 60
        },
        short_title: {
          label: '公司简称',
          value: short_title,
          max: 200
        },
        mobile: {
          label: '联系电话',
          value: mobile,
          max: 11
        },
        address: {
          label: '地址',
          value: address,
          max: 200
        },
        account: {
          label: '结算单位',
          value: cloneDeep(account),
          type: 'array',
          formType: 'accountSlot'
        },
        type: {
          show: false,
          value: this.name
        },
        // person_id: {
        //     label: '责任人',
        //     value: person_id,
        //     formType: 'treeRadio',
        //     options: 'role/all',
        //     required: false,
        // },
        remark: {
          label: '备注',
          value: remark,
          max: 200,
          required: false
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
    },
    onEdit(row, index) {
      this.index = index;
      this.setForm(row);
      this.$refs.wDialogForm.visible = true;
      this.formAction = 'SalesSettingCompany/edit';
    },
    onAdd() {
      this.index = -1;
      this.setForm({});
      this.$refs.wDialogForm.visible = true;
      this.formAction = 'SalesSettingCompany/add';
    },
    onDel(row, index) {
      this.actions.index.splice(index, 1);
      this.refresh();
    },
    done(data) {
      this.getList();
    },
    async beforeSubmit(model) {
      await this.$refs.tableFormRef.validate();
      return model;
    }
  }
};