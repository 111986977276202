export default {
  title: 'BrandSettingIndex',
  data() {
    return {
      module: 'brand_setting',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        recycle: {
          autoLink: true
        }
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id,
      title,
      status,
      remark
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '品牌名称',
          value: title,
          max: 200
        },
        remark: {
          label: '备注',
          value: remark,
          max: 200,
          required: false
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};