import { cloneDeep } from 'lodash';
import TableForm from '@/components/TableForm/index.vue';
export default {
  title: 'SalesSettingSupplierIndex',
  components: {
    TableForm
  },
  data() {
    return {
      module: 'sales_setting_supplier',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        recycle: {
          autoLink: true
        }
      },
      formAction: '',
      form: {},
      tableFormColumns: [{
        prop: 'type',
        show: false,
        defaultValue: 1
      }, {
        prop: 'account_name',
        label: '单位名称'
      }, {
        prop: 'account',
        label: '结算账户'
      }]
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id = undefined,
      mobile,
      title,
      short,
      level,
      address,
      account = [],
      remark,
      status
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '供应商名称',
          value: title,
          max: 200
        },
        short: {
          label: '供应商简称',
          value: short,
          max: 60,
          required: false
        },
        level: {
          label: '供应商级别',
          value: level,
          max: 60,
          required: false
        },
        mobile: {
          label: '电话',
          value: mobile,
          max: 60
        },
        address: {
          label: '地址',
          value: address,
          max: 60
        },
        account: {
          label: '结算单位',
          value: cloneDeep(account),
          type: 'array',
          formType: 'accountSlot'
        },
        remark: {
          label: '备注',
          value: remark,
          max: 200,
          required: false
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};