import { post } from '@/utils/request';
export default {
  name: 'UserIndex',
  data() {
    return {
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        export: false,
        layout: false,
        other: [{
          title: '解除绑定',
          type: 'primary',
          disabled: row => row.admin_id === 0,
          click: (row, $index) => this.relieve(row, $index)
        }]
      },
      formAction: '',
      actions: {
        tabs: [],
        index: '/user/index',
        columns: [{
          v: 'id',
          label: 'ID',
          searchType: 'match',
          width: '80'
        },
        // { v: 'avatar', label: '头像', searchType: false, render: 'image' },
        {
          v: 'nickname',
          label: '昵称',
          searchType: 'like'
        }, {
          v: 'admin.nickname',
          label: '绑定账号',
          searchType: 'like'
        }, {
          v: 'openid',
          label: 'openid',
          searchType: 'like'
        }, {
          v: 'created_at',
          label: '授权时间',
          searchType: 'daterange'
        }]
      },
      form: {},
      dialogFormVisible: false
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    relieve(row, index) {
      this.$confirm('是否确认解除绑定？', '提示', {
        type: 'warning'
      }).then(async () => {
        await post('user/relieve', {
          id: row.id
        });
        this.getList();
      });
    }
  }
};