var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100 p25"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_vm._v("添加")]), _c("w-table", {
    ref: "wTable",
    attrs: {
      actions: _vm.actions,
      operates: _vm.operates,
      pagination: false
    },
    on: {
      edit: _vm.onEdit,
      del: _vm.onDel
    },
    scopedSlots: _vm._u([{
      key: "accountSlot",
      fn: function ({
        row
      }) {
        return [row.account && row.account.length ? _vm._l(row.account, function (item) {
          return _c("div", {
            key: item.id,
            staticClass: "omit"
          }, [_vm._v(" 单位名称：" + _vm._s(item.account_name) + " 结算账户：" + _vm._s(item.account) + " ")]);
        }) : [_vm._v("-")]];
      }
    }])
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.form,
      action: _vm.formAction,
      width: "60%",
      beforeSubmit: _vm.beforeSubmit
    },
    on: {
      submit: _vm.done
    },
    scopedSlots: _vm._u([{
      key: "accountSlot",
      fn: function ({
        item,
        model
      }) {
        return [_c("TableForm", {
          ref: "tableFormRef",
          attrs: {
            model: model,
            prop: "account",
            columns: _vm.tableFormColumns
          }
        })];
      }
    }]),
    model: {
      value: _vm.dialogFormVisible,
      callback: function ($$v) {
        _vm.dialogFormVisible = $$v;
      },
      expression: "dialogFormVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };