export default {
  name: 'ConfigSupplier',
  props: {
    name: {
      type: String,
      required: true
    },
    group: {
      type: Number,
      default: () => {
        return 2;
      }
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      operates: {
        del: {
          show: true,
          url: 'SalesSettingSupplier/delete'
        },
        look: false,
        edit: true
      },
      actions: {
        index: [],
        sort: (row, newIndex, oldIndex) => {
          this.actions.index.splice(newIndex, 0, ...this.actions.index.splice(oldIndex, 1));
          this.actions.index = JSON.parse(JSON.stringify(this.actions.index));
          this.refresh();
        },
        columns: [{
          v: 'title',
          label: '仓库名称',
          search: false,
          sort: false
        }, {
          v: 'mobile',
          label: '联系电话',
          search: false,
          sort: false
        }, {
          v: 'address',
          label: '地址',
          search: false,
          sort: false
        }, {
          v: 'user_id',
          label: '负责人',
          search: 'person.user_id',
          searchType: 'multiple',
          sort: false,
          searchList: 'role/all',
          replace: true
        }, {
          v: 'status',
          label: '状态',
          render: 'status',
          searchType: false,
          sort: false
        }]
      },
      dialogFormVisible: false,
      formAction: 'SalesSettingSupplier/add',
      form: {},
      index: -1
    };
  },
  watch: {
    name(val, old) {
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.$w_fun.post('SalesSettingStore/index').then(response => {
        if (response.data) {
          this.actions.index = response.data.detail ? response.data.detail.value : response.data.list.data;
        }
        this.listLoading = false;
        this.refresh();
      });
    },
    refresh() {
      this.$refs.wTable && this.$refs.wTable.onRefresh();
    },
    setForm({
      id = undefined,
      mobile,
      title,
      address,
      status,
      user_id
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '仓库名称',
          value: title,
          max: 60
        },
        mobile: {
          label: '联系电话',
          value: mobile,
          max: 11
        },
        address: {
          label: '地址',
          value: address,
          max: 200
        },
        person_id: {
          label: '责任人',
          value: user_id,
          formType: 'treeSelect',
          options: 'role/all',
          required: false
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
    },
    onEdit(row, index) {
      this.index = index;
      this.setForm(row);
      this.$refs.wDialogForm.visible = true;
      this.formAction = 'SalesSettingStore/edit';
    },
    onAdd() {
      this.index = -1;
      this.setForm({});
      this.$refs.wDialogForm.visible = true;
      this.formAction = 'SalesSettingStore/add';
    },
    onDel(row, index) {
      this.actions.index.splice(index, 1);
      this.refresh();
    },
    done(data) {
      this.getList();
    }
  }
};