export default {
  title: 'DeviceDetail',
  components: {},
  data() {
    return {
      actions: {
        tabs: [{
          label: '全部',
          name: '-1'
        }],
        columns: '/DeviceDetail/columns',
        index: '/DeviceDetail/index'
      },
      operates: {
        add: false,
        edit: false,
        del: false,
        look: false,
        export: {
          p: 'device_detail/index-export'
        }
      }
    };
  },
  methods: {
    tableGetList() {
      this.$refs.tableRef.getList();
    }
  }
};