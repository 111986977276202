export default {
  title: 'DeviceCateSettingIndex',
  data() {
    return {
      module: 'device_cate_setting',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        recycle: {
          autoLink: true
        }
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id = undefined,
      title,
      remark,
      is_scan = 1,
      status
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '设备类型',
          value: title,
          max: 200
        },
        remark: {
          label: '备注',
          value: remark,
          max: 200,
          required: false
        },
        is_scan: {
          label: '是否扫码',
          value: is_scan,
          formType: 'select',
          options: [{
            value: 0,
            label: '否'
          }, {
            value: 1,
            label: '是'
          }]
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};