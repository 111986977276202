var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100 p25"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onAdd
    }
  }, [_vm._v("添加")]), _c("w-table", {
    ref: "wTable",
    attrs: {
      actions: _vm.actions,
      operates: _vm.operates,
      pagination: false
    },
    on: {
      edit: _vm.onEdit,
      del: _vm.onDel
    }
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.form,
      action: _vm.formAction,
      width: "60%"
    },
    on: {
      submit: _vm.done
    },
    model: {
      value: _vm.dialogFormVisible,
      callback: function ($$v) {
        _vm.dialogFormVisible = $$v;
      },
      expression: "dialogFormVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };