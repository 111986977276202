export default {
  name: 'AdminIndex',
  data() {
    return {
      module: 'admin',
      operates: {
        del: false,
        look: false,
        add: true
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id,
      nickname,
      username,
      password,
      dept_id,
      status
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        nickname: {
          label: '姓名',
          value: nickname
        },
        dept_id: {
          label: '部门',
          value: dept_id,
          formType: 'select',
          options: '/dept/select'
        },
        username: {
          label: '账号',
          value: username
        },
        password: {
          label: '密码',
          value: password,
          required: false,
          tooltip: '不修改密码请留空'
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    }
  }
};