import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import { post } from '@/utils/request';
import { makeDownFileName, exportFile } from '@/utils/xlsx';
export default {
  title: 'ProductIndex',
  data() {
    return {
      module: 'product',
      actions: {
        index: async query => {
          const {
            data
          } = await post('/product/index', query, undefined, false);
          data.list.data = data.list.data.reduce((pre, row, index) => {
            index = String(index + 1);
            // 只存在一个销售单，解构销售单
            if (row.marketProduct && row.marketProduct.length === 1) {
              pre.push({
                ...row,
                showId: row.id,
                tableIndex: index,
                marketProduct: row.marketProduct[0]
              });
            }
            // 存在销售单，拆分销售单为多条数据
            else if (row.marketProduct && row.marketProduct.length > 1) {
              row.marketProduct.forEach((marketProduct, i) => pre.push({
                ...row,
                showId: `${row.id}_${i}`,
                tableIndex: `${index}.${i + 1}`,
                marketProduct
              }));
            } else {
              pre.push({
                ...row,
                showId: row.id,
                tableIndex: index,
                marketProduct: {}
              });
            }
            return pre;
          }, []);
          return data;
        }
      },
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        export: {
          p: false,
          click: () => {
            const table = this.$refs.tableRef.$refs.table;
            table.downFileName = makeDownFileName(this);
            exportFile(table, this.spanMethods);
          }
        }
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    makeLen(index, cb) {
      let len = 0;
      this.$refs.tableRef.$refs.table.data.slice(index).every(row => cb(row) && ++len);
      return len;
    },
    spanMethods({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      var _row$marketProduct, _row$marketProduct$ma, _list$marketProduct, _list$marketProduct$m;
      const list = this.$refs.tableRef.$refs.table.data;
      let [rowspan, colspan] = [1, 1];
      switch (column.property || column.v) {
        // 一级合并 采购单
        case 'ware.id_text':
        case 'ware.accounts_payable':
        case 'profit':
        case 'ware.supplier_id':
        case 'ware.contract_code':
          rowspan = rowIndex === 0 || row.ware_id !== list[rowIndex - 1].ware_id ? this.makeLen(rowIndex, ({
            ware_id
          }) => ware_id === row.ware_id) : 0;
          break;
        // 二级合并 设备
        case 'device.brand_id':
        case 'device.device_cate_id':
        case 'device.short':
          rowspan = rowIndex === 0 || row.ware_id !== list[rowIndex - 1].ware_id || row.device.id !== list[rowIndex - 1].device.id ? this.makeLen(rowIndex, ({
            ware_id,
            device: {
              id
            }
          }) => ware_id === row.ware_id && id === row.device.id) : 0;
          break;
        // 三级合并 仓库
        case 'store_id':
        case 'ware_product.num':
        case 'ware_product.price':
          rowspan = rowIndex === 0 || row.ware_id !== list[rowIndex - 1].ware_id || row.device.id !== list[rowIndex - 1].device.id || row.store_id !== list[rowIndex - 1].store_id ? this.makeLen(rowIndex, ({
            ware_id,
            device: {
              id
            },
            store_id
          }) => ware_id === row.ware_id && id === row.device.id && store_id === row.store_id) : 0;
          break;
        // 四级合并 销售单
        case 'marketProduct.market.id_text':
        case 'marketProduct.market.admin_id':
        case 'marketProduct.market.supplier_id':
        case 'marketProduct.market.contract_code':
          rowspan = rowIndex === 0 || row.ware_id !== list[rowIndex - 1].ware_id || ((_row$marketProduct = row.marketProduct) === null || _row$marketProduct === void 0 ? void 0 : (_row$marketProduct$ma = _row$marketProduct.market) === null || _row$marketProduct$ma === void 0 ? void 0 : _row$marketProduct$ma.id) !== ((_list$marketProduct = list[rowIndex - 1].marketProduct) === null || _list$marketProduct === void 0 ? void 0 : (_list$marketProduct$m = _list$marketProduct.market) === null || _list$marketProduct$m === void 0 ? void 0 : _list$marketProduct$m.id) ? this.makeLen(rowIndex, ({
            ware_id,
            marketProduct
          }) => {
            var _marketProduct$market, _row$marketProduct2, _row$marketProduct2$m;
            return ware_id === row.ware_id && (marketProduct === null || marketProduct === void 0 ? void 0 : (_marketProduct$market = marketProduct.market) === null || _marketProduct$market === void 0 ? void 0 : _marketProduct$market.id) === (row === null || row === void 0 ? void 0 : (_row$marketProduct2 = row.marketProduct) === null || _row$marketProduct2 === void 0 ? void 0 : (_row$marketProduct2$m = _row$marketProduct2.market) === null || _row$marketProduct2$m === void 0 ? void 0 : _row$marketProduct2$m.id);
          }) : 0;
          break;
        // 销售单合并
        case 'market_money':
        case 'num':
        case 'created_at':
        case 'sn_number':
          rowspan = rowIndex === 0 || row.id !== list[rowIndex - 1].id ? this.makeLen(rowIndex, ({
            id
          }) => id === row.id) : 0;
          break;
      }
      return {
        rowspan,
        colspan
      };
    }
  }
};