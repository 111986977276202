var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module
    },
    on: {
      add: _vm.onEdit,
      edit: row => _vm.onEdit(row, false),
      look: row => _vm.onEdit(row, true)
    }
  }), _c("Edit", {
    ref: "editRef",
    on: {
      done: _vm.getList
    }
  }), _c("UploadInvoice", {
    ref: "uploadInvoiceRef",
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };