import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import { post } from '@/utils/request';
import { getToken } from '@/utils/auth';
import { objToQuery } from '@/libs/w';
import { makeDownFileName, exportFile } from '@/utils/xlsx';
import EditSn from './components/editSn.vue';
export default {
  title: 'Sn',
  components: {
    EditSn
  },
  data() {
    return {
      module: 'sn',
      actions: {
        tabs: [{
          label: '全部',
          name: '-1'
        }],
        columns: '/sn/columns',
        index: async query => {
          const {
            data
          } = await post('/sn/index', query, undefined, false);
          data.list.data = data.list.data.reduce((pre, row, index) => {
            index = String(index + 1);
            // 只存在一个销售单，解构销售单
            if (row.marketProduct && row.marketProduct.length === 1) {
              pre.push({
                ...row,
                showId: row.id,
                tableIndex: index,
                marketProduct: row.marketProduct[0]
              });
            }
            // 存在销售单，拆分销售单为多条数据
            else if (row.marketProduct && row.marketProduct.length > 1) {
              row.marketProduct.forEach((marketProduct, i) => pre.push({
                ...row,
                showId: `${row.id}_${i}`,
                tableIndex: `${index}.${i + 1}`,
                marketProduct
              }));
            } else {
              pre.push({
                ...row,
                showId: row.id,
                tableIndex: index,
                marketProduct: {}
              });
            }
            return pre;
          }, []);
          return data;
        }
      },
      operates: {
        add: false,
        edit: {
          show: row => row.sn_number !== '\\',
          disabled: row => row.status > 1
        },
        del: false,
        look: false,
        export: {
          p: 'sn/index-export',
          click: () => {
            const table = this.$refs.tableRef.$refs.table.filter;
            window.open(process.env.VUE_APP_BASE_API + '/export/run' + objToQuery({
              Authorization: getToken(),
              ...table.filter,
              api: 'sn/index',
              _export: 1,
              file_name: table.downFileName || 'SN明细表'
            }));
          }

          // click: () => {
          //   const table = this.$refs.tableRef.$refs.table
          //   table.downFileName = makeDownFileName(this)
          //   exportFile(table, this.spanMethods)
          // },
        }
      }
    };
  },

  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    makeLen(data, index, cb) {
      let len = 0;
      data.slice(index).every(row => cb(row) && ++len);
      return len;
    },
    spanMethods({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      const list = this.$refs.tableRef.$refs.table.data;
      let [rowspan, colspan] = [1, 1];
      if (['sn_number', 'device_model_id', 'store_id', 'num', 'ware_id_text', 'wareProduct.unit_price', 'created_at', 'status'].includes(column.property || column.v)) {
        rowspan = rowIndex === 0 || row.id !== list[rowIndex - 1].id ? this.makeLen(list, rowIndex, ({
          id
        }) => id === row.id) : 0;
      }
      return {
        rowspan,
        colspan
      };
    },
    onEdit(row) {
      this.$refs.editSnRef.open(row);
    }
  }
};