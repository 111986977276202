import { post } from '@/utils/request';
import { intGt0 } from '@/components/w/components/w-form/patterns';
export default {
  data() {
    return {
      title: '上传发票',
      action: 'payment/upload_invoice',
      form: {}
    };
  },
  methods: {
    async setForm({
      id,
      ware
    }) {
      const info = await post('/ware/getInfo', {
        id: ware.id
      }, {}, true);
      const form = {
        ware_id: {
          show: false,
          value: ware.id
        },
        finance_id: {
          show: false,
          value: id
        },
        id: {
          show: false,
          value: id
        },
        type: {
          value: 1,
          show: false
        },
        data: {
          label: '详情',
          value: info.data.info.product.map(({
            id: ware_product_id,
            code,
            num,
            money,
            device_model_id,
            store_id
          }) => ({
            code,
            ware_product_id,
            num,
            money,
            device_model_id,
            store_id,
            number: ''
          })),
          type: 'array',
          formType: 'dataSlot',
          validator: (r, v) => v.filter(({
            num
          }) => num > 0 && intGt0.reg.test(num)).length === 0 ? Promise.reject('请至少填写一条有效的产品开出数量') : Promise.resolve()
        },
        money: {
          label: '发票金额',
          value: '',
          formType: 'number',
          attrs: {
            append: '元'
          }
        },
        file: {
          label: '凭证文件',
          value: '',
          formType: 'upload',
          attrs: {
            accept: '.pdf,.jpg,.png,.jpeg,.gif'
          }
        }
      };
      this.form = form;
    },
    async open(row) {
      await this.setForm(row);
      this.$refs.dialogFormRef.visible = true;
    },
    beforeSubmit(model) {
      model.data = model.data.filter(({
        num
      }) => num > 0 && intGt0.reg.test(num));
      return model;
    }
  }
};