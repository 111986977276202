import Edit from './dialog/edit.vue';
import ContractDialog from './dialog/contractDialog';
import Logistics from './dialog/logistics';
import Inbound from './dialog/inbound.vue';
import PaymentEdit from '../payment/dialog/edit.vue';
import UploadInvoice from '../payment/dialog/uploadInvoice.vue';
import { post } from "@/utils/request";
export default {
  title: 'WareIndex',
  components: {
    Edit,
    ContractDialog,
    Logistics,
    Inbound,
    PaymentEdit,
    UploadInvoice
  },
  data() {
    return {
      module: 'ware',
      operates: {
        del: false,
        add: true,
        edit: {
          // disabled: row => row.status > 5,
        },
        export: true,
        other: [{
          title: '撤回',
          show: row => row.status === 10,
          click: (row, $index) => this.withdraw(row, $index)
        }, {
          p: 'ware/upload_contract',
          title: '上传合同',
          show: row => row.status === 10,
          click: (row, $index) => this.contract(row, $index)
        }, {
          p: 'ware/payment',
          title: '付款单',
          show: row => row.status >= 15 && row.status <= 40 && row.pay_examine < 2,
          click: async row => {
            if (row.finance) {
              const {
                id,
                ware_id,
                s_id,
                settlement_company_id,
                company_id,
                accounts_payable,
                abstract
              } = row.finance;
              this.$refs.paymentEditRef.open({
                id,
                ware: {
                  id_text: row.id_text
                },
                ware_id,
                s_id,
                settlement_company_id,
                company_id,
                accounts_payable,
                abstract
              }, false);
            } else {
              const {
                id: ware_id,
                supplier_id: s_id,
                settlement_company_id,
                company_id,
                accounts_payable,
                abstract
              } = row;
              this.$refs.paymentEditRef.open({
                ware_id,
                s_id,
                settlement_company_id,
                company_id,
                accounts_payable,
                abstract
              }, false);
            }
          }
        }, {
          p: 'ware/upload_invoice',
          title: '上传发票',
          show: row => row.status !== 45 && row.pay_examine >= 2 && row.pay_examine < 4,
          click: row => this.$refs.uploadInvoiceRef.open({
            id: row.finance.id,
            ware: {
              id: row.id
            }
          })
        }, {
          title: '物流信息',
          show: row => row.status === 15,
          click: row => this.$refs.logisticsRef.open(row, false)
        }, {
          p: 'ware/warehousing',
          title: '入库',
          show: row => row.status >= 20 && row.status < 30,
          click: (row, $index) => {
            this.currentIndex = $index;
            this.$refs.inboundRef.toggleVisible(true);
          }
        }]
      },
      data: [],
      currentIndex: undefined
    };
  },
  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    onGetList(list) {
      this.data = list.list.data;
    },
    onEdit(row, readonly = false) {
      this.$refs.editRef.open(row, readonly);
    },
    contract(row, index) {
      this.$refs.contract_dialog.open(row, false);
    },
    async withdraw(row, index) {
      const {
        value
      } = await this.$prompt('请填写撤回原因', '提示', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        type: 'warning',
        inputValidator: v => !!v && !!v.trim() || '撤回原因不能为空',
        inputErrorMessage: '撤回原因不能为空'
      });
      await post('/ware/withdraw', {
        id: row.id,
        reason: value
      });
      this.getList();
    }
  }
};