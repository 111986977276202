var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "dialogForm",
    attrs: {
      title: _vm.title,
      form: _vm.form,
      action: _vm.action
    },
    on: {
      done: data => _vm.$emit("done", data)
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };