import "core-js/modules/es.array.reduce.js";
import { post } from '@/utils/request';
import { add, multiply } from 'lodash';
import TableForm from '@/components/TableForm/index.vue';
export default {
  components: {
    TableForm
  },
  data() {
    return {
      readonly: false,
      title: '',
      action: '',
      form: {},
      row: {},
      status: 0,
      tableFormColumns: [{
        prop: 'type',
        show: false,
        defaultValue: 2
      }, {
        prop: 'store_id',
        label: '发货仓库',
        width: 140,
        formType: 'select',
        options: '/SalesSettingStore/select',
        methods: {
          change: async (v, row, $index) => {
            row.code = '';
            row.product_name = '';
            row.device_model_id = '';
            row.unit = '';
            const p_options = this.$refs.tableFormRef.options;
            this.$set(p_options.private[$index], 'code', []);
            this.$set(p_options.private[$index], 'product_name', []);
            if (!v) return;
            const list = (await post('/wareProduct/get_product', {
              market_id: this.row.id || undefined,
              store_id: v
            })).data.list;
            this.$set(p_options.private[$index], 'code', list.map(({
              code,
              device_model_id,
              product_name,
              number,
              unit
            }) => {
              const newCode = `${code} (库存 ${number})`;
              return {
                label: newCode,
                value: code,
                device_model_id,
                code,
                product_name,
                unit
              };
            }));
            this.$set(p_options.private[$index], 'product_name', list.map(({
              code,
              device_model_id,
              product_name,
              number,
              unit
            }) => {
              const newProduct_name = `${product_name} (库存 ${number})`;
              return {
                label: newProduct_name,
                value: product_name,
                device_model_id,
                code,
                product_name,
                unit
              };
            }));
          }
        }
      }, {
        prop: 'code',
        label: '存货编号',
        width: 140,
        formType: 'select',
        privateOption: async (row, $index) => (await post('/wareProduct/get_product', {
          market_id: this.row.id || undefined,
          store_id: row.store_id
        })).data.list.map(({
          code,
          device_model_id,
          product_name,
          number,
          unit
        }) => {
          const newCode = `${code} (库存 ${number})`;
          return {
            label: newCode,
            value: code,
            device_model_id,
            code,
            product_name,
            unit
          };
        }),
        methods: {
          change: (v, row, $index) => {
            row.product_name = '';
            row.device_model_id = '';
            row.unit = '';
            if (!v) return;
            const current = this.$refs.tableFormRef.options.private[$index].code.find(({
              value
            }) => value === v);
            if (!current) return;
            row.product_name = current.product_name;
            row.device_model_id = current.device_model_id;
            row.unit = current.unit;
          }
        }
      }, {
        prop: 'product_name',
        label: '简写',
        width: 140,
        formType: 'select',
        privateOption: async (row, $index) => (await post('/wareProduct/get_product', {
          market_id: this.row.id || undefined,
          store_id: row.store_id
        })).data.list.map(({
          code,
          device_model_id,
          product_name,
          number,
          unit
        }) => {
          const newProduct_name = `${product_name} (库存 ${number})`;
          return {
            label: newProduct_name,
            value: product_name,
            device_model_id,
            code,
            product_name,
            unit
          };
        }),
        methods: {
          change: (v, row, $index) => {
            row.code = '';
            row.device_model_id = '';
            row.unit = '';
            if (!v) return;
            const current = this.$refs.tableFormRef.options.private[$index].product_name.find(({
              value
            }) => value === v);
            if (!current) return;
            row.code = current.code;
            row.device_model_id = current.device_model_id;
            row.unit = current.unit;
          }
        }
      }, {
        prop: 'unit',
        label: '单位',
        width: 100,
        formType: 'input',
        attrs: {
          disabled: true
        },
        validate: {
          required: false,
          max: 20
        }
      }, {
        prop: 'num',
        label: '数量',
        width: 100,
        formType: 'number',
        type: 'number',
        methods: {
          change: (e, row) => this.makeMoney(row)
        },
        validate: {
          pattern: 'intGt0'
        }
      }, {
        prop: 'price',
        label: '单价',
        width: 120,
        formType: 'number',
        type: 'number',
        attrs: {
          append: '元'
        },
        methods: {
          change: (e, row) => this.makeMoney(row)
        },
        validate: {
          min: 0,
          pattern: 'price'
        }
      }, {
        prop: 'money',
        label: '金额',
        width: 120,
        formType: 'input',
        attrs: {
          disabled: true,
          append: '元'
        }
      }, {
        prop: 'bill_id',
        label: '票据类型',
        width: 140,
        formType: 'select',
        options: '/InvoiceSetting/select'
      }, {
        label: '备注',
        prop: 'remark',
        width: 200,
        formType: 'input',
        validate: {
          required: false
        }
      }]
    };
  },
  methods: {
    getForm() {
      return this.$refs.dialogFormRef.$refs.form;
    },
    async open(row = {}, readonly = false) {
      if (!row || !row.id) {
        this.title = '添加';
        this.action = 'market/add';
      } else {
        this.title = readonly ? '详情' : '编辑';
        this.status = row.status;
        if (row.status > 5) {
          this.action = 'market/price_save';
        } else {
          this.action = 'market/edit';
        }
      }
      this.readonly = readonly;
      this.row = row;
      this.setForm(row);
    },
    async setForm({
      id,
      supplier_id,
      settlement_company_id,
      abstract,
      admin_id,
      collection_time,
      company_id,
      address,
      bill_id,
      product = [],
      total_cost,
      contract_code,
      contract,
      status,
      reason
    }) {
      if (status > 5) {
        ;
        [1, 2, 3, 8, 9].forEach(i => this.$set(this.tableFormColumns[i], 'readonly', true));
      }
      this.form = {
        id: {
          show: false,
          value: id
        },
        supplier_id: {
          readonly: status > 5,
          label: '客户单位',
          value: supplier_id,
          formType: 'select',
          options: '/SalesSettingCompany/select?type=11',
          methods: {
            change: async v => {
              const {
                model,
                options
              } = this.getForm();
              options.settlement_company_id = [];
              model.settlement_company_id = '';
              if (!v) return;
              options.settlement_company_id = (await post('/SalesSettingCompany/settlement', {
                id: v
              })).data.list;
            }
          }
        },
        settlement_company_id: {
          readonly: status > 5,
          label: '结算单位',
          value: settlement_company_id,
          formType: 'select',
          options: supplier_id ? {
            url: '/SalesSettingCompany/settlement',
            data: {
              id: supplier_id
            }
          } : []
        },
        collection_time: {
          readonly: status > 5,
          label: '收款日期',
          value: collection_time,
          formType: 'datePicker',
          attrs: {
            pickerOptions: {
              disabledDate: {
                start: 'today'
              }
            },
            style: {
              width: '100% !important'
            }
          }
        },
        company_id: {
          readonly: status > 5,
          label: '合同公司',
          value: company_id,
          formType: 'select',
          options: '/SalesSettingCompany/select?type=0'
        },
        address: {
          readonly: status > 5,
          label: '收货地址',
          value: address
        },
        bill_id: {
          readonly: status > 5,
          label: '票据类型',
          value: bill_id,
          formType: 'select',
          options: '/invoice_setting/select'
        },
        product: {
          width: '100%',
          label: '详情',
          value: JSON.parse(JSON.stringify(product)),
          readonlyValue: JSON.parse(JSON.stringify(product)).map(item => ({
            ...item,
            price: item.price + '元',
            money: item.money + '元'
          })),
          formType: 'productSlot',
          type: 'array'
        },
        total_cost: {
          label: '合计金额',
          value: total_cost,
          readonlyValue: total_cost + '元',
          pattern: 'price',
          formType: 'number',
          type: 'number',
          attrs: {
            disabled: true,
            append: '元'
          }
        },
        abstract: {
          readonly: status > 5,
          width: '100%',
          label: '摘要',
          value: abstract,
          formType: 'textarea',
          required: false
        },
        contract_code: {
          readonly: status > 5,
          value: contract_code,
          label: '合同编码',
          max: 200,
          required: false
        },
        contract: {
          readonly: status > 5,
          show: model => !!model.contract_code,
          label: '合同',
          value: contract,
          formType: 'upload',
          attrs: {
            accept: '.pdf',
            tip: '只能上传 pdf，最大20M',
            limit: 1,
            data: {
              type: 'static'
            }
          }
        },
        reason: {
          show: model => status === 45,
          label: '撤回原因',
          value: reason
        }
      };
      await this.$nextTick();
      this.$refs.dialogFormRef.visible = true;
    },
    makeMoney(row) {
      if (!(row.num > 0) || !(row.price > 0)) return row.money = 0;
      row.money = multiply(row.num, row.price);
      this.makeAmount();
    },
    makeAmount() {
      const {
        model
      } = this.getForm();
      model.total_cost = model.product.reduce((pre, {
        money
      }) => money ? add(money, pre) : pre, 0);
    },
    async beforeSubmit(model) {
      await this.$refs.tableFormRef.validate();
      const product = model.product.map(({
        device_model_id
      }) => JSON.stringify({
        device_model_id
      }));
      if (new Set(product).size !== product.length) {
        this.$message.error('存在相同产品');
        return Promise.reject();
      }
      !model.contract_code && delete model.contract;
      return model;
    }
  }
};