export default {
  name: 'ConfigText',
  props: {
    name: {
      type: String,
      required: true
    },
    group: {
      type: Number,
      default: () => {
        return 2;
      }
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      model: {
        value: ''
      }
    };
  },
  watch: {
    name(val, old) {
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.$w_fun.post('config/get', {
        name: this.name
      }).then(response => {
        if (response.data) {
          this.model.value = response.data.detail ? response.data.detail.value : '';
        }
        this.listLoading = false;
      });
    },
    updateData() {
      if (this.model) {
        this.$w_fun.post('config/save', {
          value: this.model.value,
          name: this.name,
          group: this.group,
          title: this.title,
          type: 0
        }).then(response => {
          this.getList();
        });
      } else {
        this.$notify({
          message: '没有需要保存的数据',
          type: 'error',
          duration: 2000
        });
      }
    },
    handleSave() {
      this.updateData();
    }
  }
};