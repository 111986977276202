import { post } from '@/utils/request';
import Edit from './dialog/edit.vue';
import UploadInvoice from './dialog/uploadInvoice.vue';
export default {
  title: 'CollectionIndex',
  components: {
    Edit,
    UploadInvoice
  },
  data() {
    return {
      module: 'collection',
      operates: {
        del: false,
        look: true,
        add: true,
        edit: {
          disabled: row => row.market.pay_examine >= 2
        },
        export: true,
        other: [{
          title: '上传发票',
          type: 'primary',
          disabled: row => row.market.pay_examine < 2 || row.market.pay_examine >= 4,
          click: row => this.$refs.uploadInvoiceRef.open(row)
        }]
      }
    };
  },
  async mounted() {
    const market_id = this.$route.query.market_id;
    if (!market_id) return;
    this.$router.replace({
      query: {
        ...this.$route.query,
        market_id: undefined
      }
    });
    const {
      data: {
        list: {
          data
        }
      }
    } = await post('/market/index', {
      id_match: market_id
    });
    const {
      id,
      supplier_id: s_id,
      settlement_company_id,
      company_id,
      total_cost: accounts_payable,
      abstract
    } = data[0];
    this.onEdit({
      ware_id: id,
      s_id,
      settlement_company_id,
      company_id,
      accounts_payable,
      abstract
    }, false);
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row = {}, readonly = false) {
      this.$refs.editRef.open(row, readonly);
    }
  }
};