import "core-js/modules/es.array.reduce.js";
export default {
  title: 'WareProductIndex',
  data() {
    return {
      module: 'ware_product',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        export: true
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id,
      ware_id,
      type,
      code,
      product_name,
      store_id,
      unit,
      num,
      price,
      money,
      bill_id,
      status,
      remark
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        ware_id: {
          label: '入库单id',
          value: ware_id,
          formType: 'number'
        },
        type: {
          label: '1入库单2出库单',
          value: type,
          formType: 'number'
        },
        code: {
          label: '编码',
          value: code
        },
        product_name: {
          label: '名称',
          value: product_name
        },
        store_id: {
          label: '仓库id',
          value: store_id,
          formType: 'number'
        },
        unit: {
          label: '单位',
          value: unit
        },
        num: {
          label: '数量',
          value: num,
          formType: 'number'
        },
        price: {
          label: '价格',
          value: price,
          formType: 'number'
        },
        money: {
          label: '总价',
          value: money,
          formType: 'number'
        },
        bill_id: {
          label: '发票类型',
          value: bill_id,
          formType: 'number'
        },
        status: {
          label: '',
          value: status,
          formType: 'status'
        },
        remark: {
          label: '备注',
          value: remark
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    },
    spanMethods({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      const list = this.$refs.tableRef.$refs.table.data;
      let [rowspan, colspan] = [1, 1];
      ['ware.created_at', 'ware.id_text', 'ware.admin_id', 'contract_code', 'ware.accounts_payable', 'invoice_money', 'invoice_list'].includes(column.property) && (rowspan = rowIndex === 0 || row.ware.id_text !== list[rowIndex - 1].ware.id_text ? list.reduce((pre, {
        ware: {
          id_text
        }
      }) => id_text === row.ware.id_text ? ++pre : pre, 0) : 0);
      return {
        rowspan,
        colspan
      };
    }
  }
};