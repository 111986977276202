import { intGt0 } from '@/components/w/components/w-form/patterns';
import { post } from '@/utils/request';
export default {
  name: 'logisticsDialog',
  props: {
    row: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    product() {
      return this.row.product ? this.row.product.map(({
        id,
        ware_id,
        device_model_id,
        code,
        product_name,
        num,
        operate_num,
        device: {
          cate: {
            is_scan
          }
        }
      }) => ({
        id,
        ware_id,
        device_model_id,
        code,
        product_name,
        num,
        operate_num,
        surplus_num: num - operate_num,
        is_scan,
        product: ''
      })) : [];
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    toggleVisible(toggle) {
      this.visible = toggle === undefined ? !this.visible : toggle;
    },
    isFinish(row) {
      return row.num <= row.operate_num;
    },
    validateNum(row) {
      return !intGt0.reg.test(row.num) || row.num > row.surplus_num || row.num <= 0;
    },
    makeSn(sn) {
      return sn.split('\n').map(i => i.trim()).filter(i => i);
    },
    validateSn(row) {
      const len = row.product.split('\n').map(i => i.trim()).filter(i => i).length;
      return len > row.surplus_num || len <= 0;
    },
    async warehousing(row) {
      const data = {
        id: row.ware_id,
        device_model_id: row.device_model_id,
        market_product_id: row.id
      };
      if (row.is_scan) {
        if (!row.product) return this.$message.error('SN 号不能为空');
        const product = row.product.split('\n').map(i => i.trim()).filter(i => i);
        if (product.length === 0) return this.$message.error('SN 号不能为空');
        if (product.length > row.surplus_num) return this.$message.error('超过待出库数量');
        data.product = product;
      } else {
        if (!intGt0.reg.test(row.product)) return this.$message.error('数量应为正整数');
        if (row.product > row.surplus_num) return this.$message.error('超过待出库数量');
        data.num = row.product;
      }
      await post('/market/out_bound', data, undefined, true);
      this.$emit('done');
    }
  }
};