export default {
  name: 'ConfigBrand',
  props: {
    name: {
      type: String,
      required: true
    },
    group: {
      type: Number,
      default: () => {
        return 2;
      }
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      operates: {
        del: {
          show: true,
          url: 'deviceCateSetting/delete'
        },
        look: false,
        edit: true
      },
      actions: {
        index: [],
        sort: (row, newIndex, oldIndex) => {
          this.actions.index.splice(newIndex, 0, ...this.actions.index.splice(oldIndex, 1));
          this.actions.index = JSON.parse(JSON.stringify(this.actions.index));
          this.refresh();
        },
        columns: [{
          v: 'title',
          label: '设备类型',
          search: false,
          sort: false
        }, {
          v: 'is_scan',
          label: '是否扫码',
          searchType: 'multiple',
          sort: false,
          searchList: [{
            value: 0,
            label: '否'
          }, {
            value: 1,
            label: '是'
          }],
          replace: true
        }, {
          v: 'remark',
          label: '备注',
          search: false,
          sort: false
        }, {
          v: 'status',
          label: '状态',
          render: 'status',
          searchType: false,
          sort: false
        }]
      },
      dialogFormVisible: false,
      formAction: 'BrandSetting/add',
      form: {},
      index: -1
    };
  },
  watch: {
    name(val, old) {
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.$w_fun.post('deviceCateSetting/index').then(response => {
        if (response.data) {
          this.actions.index = response.data.detail ? response.data.detail.value : response.data.list.data;
        }
        this.listLoading = false;
        this.refresh();
      });
    },
    refresh() {
      this.$refs.wTable && this.$refs.wTable.onRefresh();
    },
    setForm({
      id = undefined,
      title,
      remark,
      is_scan = 1,
      status
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '设备类型',
          value: title,
          max: 200
        },
        remark: {
          label: '备注',
          value: remark,
          max: 200,
          required: false
        },
        is_scan: {
          label: '是否扫码',
          value: is_scan,
          formType: 'select',
          options: [{
            value: 0,
            label: '否'
          }, {
            value: 1,
            label: '是'
          }]
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
    },
    onEdit(row, index) {
      this.index = index;
      this.setForm(row);
      this.$refs.wDialogForm.visible = true;
      this.formAction = 'deviceCateSetting/edit';
    },
    onAdd() {
      this.index = -1;
      this.setForm({});
      this.$refs.wDialogForm.visible = true;
      this.formAction = 'deviceCateSetting/add';
    },
    onDel(row, index) {
      this.actions.index.splice(index, 1);
      this.refresh();
    },
    done(data) {
      this.getList();
    }
  }
};