var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      "tree-props": {
        children: "children",
        hasChildren: "id"
      },
      lazy: true,
      load: _vm.onLoad,
      module: _vm.module,
      pagination: false
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function ({
        row
      }) {
        return [_c("i", {
          class: row.icon
        })];
      }
    }])
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "权限",
      form: _vm.form,
      action: _vm.formAction,
      width: "60%"
    },
    on: {
      done: function ($event) {
        return _vm.getList(true);
      }
    },
    scopedSlots: _vm._u([{
      key: "iconSlot",
      fn: function ({
        item,
        model
      }) {
        return [_c("w-icon", {
          model: {
            value: model.icon,
            callback: function ($$v) {
              _vm.$set(model, "icon", $$v);
            },
            expression: "model.icon"
          }
        })];
      }
    }]),
    model: {
      value: _vm.dialogFormVisible,
      callback: function ($$v) {
        _vm.dialogFormVisible = $$v;
      },
      expression: "dialogFormVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };