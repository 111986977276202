export default {
  name: 'targetDialog',
  data() {
    return {
      title: '',
      form: {},
      action: ''
    };
  },
  methods: {
    setForm({
      id,
      contract = '',
      accounts_payable = 0
    }) {
      const form = {
        accounts_payable: {
          show: false,
          value: accounts_payable
        },
        contract_code: {
          value: '',
          label: '合同编码',
          max: 200
        },
        contract: {
          label: '合同',
          value: contract,
          formType: 'upload',
          attrs: {
            accept: '.pdf',
            tip: '只能上传 pdf，最大20M',
            limit: 1,
            data: {
              'type': 'static'
            }
          }
        }
      };
      if (id) {
        form.id = {
          show: false,
          value: id
        };
        this.title = '上传合同';
        this.action = '/ware/upload_contract';
      }
      this.form = form;
    },
    open(data) {
      this.$refs.dialogForm.visible = true;
      this.$nextTick(() => this.setForm(data));
    }
  }
};