var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "dialogForm",
    attrs: {
      readonly: _vm.readonly,
      title: _vm.title,
      action: _vm.action,
      form: _vm.form,
      width: "900px"
    },
    on: {
      done: data => _vm.$emit("done", data)
    },
    scopedSlots: _vm._u([{
      key: "deviceSlot",
      fn: function ({
        item,
        model
      }) {
        return [_c("el-table", {
          attrs: {
            data: model[item.prop],
            stripe: "",
            border: "",
            size: "mini"
          }
        }, [_c("el-table-column", {
          attrs: {
            type: "index",
            label: "序号",
            align: "center"
          }
        }), _c("el-table-column", {
          attrs: {
            label: "型号",
            prop: "device.code"
          }
        }), _c("el-table-column", {
          attrs: {
            label: "采购数量",
            prop: "info.num"
          }
        }), _c("el-table-column", {
          attrs: {
            label: "开进数量",
            prop: "number"
          }
        }), _c("el-table-column", {
          attrs: {
            label: "未开票数量"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              row
            }) {
              return [_vm._v(" " + _vm._s((Number(row.info.num) || 0) - (Number(row.number) || 0)) + " ")];
            }
          }], null, true)
        })], 1)];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };