var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "tableRef",
    attrs: {
      operates: _vm.operates,
      module: _vm.module
    },
    on: {
      add: _vm.onEdit,
      edit: row => _vm.onEdit(row, false),
      look: row => _vm.onEdit(row, true),
      getList: _vm.onGetList
    },
    scopedSlots: _vm._u([{
      key: "logistics_listSlot",
      fn: function ({
        row,
        $index
      }) {
        return [_vm._v(" " + _vm._s(row.logistics_list.join("，") || "-") + " ")];
      }
    }])
  }), _c("Edit", {
    ref: "editRef",
    on: {
      done: _vm.getList
    }
  }), _c("ContractDialog", {
    ref: "contract_dialog",
    on: {
      done: _vm.getList
    }
  }), _c("Logistics", {
    ref: "logisticsRef",
    on: {
      done: _vm.getList
    }
  }), _c("Inbound", {
    ref: "inboundRef",
    attrs: {
      row: _vm.data[_vm.currentIndex]
    },
    on: {
      done: _vm.getList
    }
  }), _c("PaymentEdit", {
    ref: "paymentEditRef",
    on: {
      done: _vm.getList
    }
  }), _c("UploadInvoice", {
    ref: "uploadInvoiceRef",
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };