import { makeDownFileName, exportFile } from '@/utils/xlsx';
import Edit from './dialog/edit.vue';
export default {
  title: 'financeDetail',
  components: {
    Edit
  },
  data() {
    return {
      module: 'financeDetail',
      actions: {
        tabs: [{
          label: '全部',
          name: '-1'
        }],
        columns: '/financeDetail/columns',
        index: '/financeDetail/index'
      },
      operates: {
        add: false,
        edit: true,
        del: false,
        look: false,
        export: {
          p: false,
          click: () => {
            const table = this.$refs.tableRef.$refs.table;
            table.downFileName = makeDownFileName(this);
            exportFile(table);
          }
        }
        // other: [
        //   {
        //     show: this.isShowButton,
        //     title: '作废',
        //     type: 'primary',
        //     click: async row => {
        //       await this.$confirm('确认作废发票吗？', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning',
        //       })
        //       await post('/finance_detail/cancel', { id: row.id })
        //       this.tableGetList()
        //     },
        //   },
        // ],
      }
    };
  },

  methods: {
    tableGetList() {
      this.$refs.tableRef.getList();
    },
    isShowButton(row) {
      return row.status === 1;
    },
    open(row) {
      this.$refs.editRef.open(row);
    }
  }
};