var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    attrs: {
      type: "card"
    },
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, _vm._l(_vm.configs, function (config) {
    return _c("el-tab-pane", {
      key: config.group,
      attrs: {
        label: config.title,
        name: "" + config.group
      }
    }, [_c("el-container", {
      staticStyle: {
        height: "700px",
        border: "1px solid #eee"
      }
    }, [_c("el-aside", {
      staticStyle: {
        "border-right": "1px solid #eee"
      },
      attrs: {
        width: "200px",
        height: "100%"
      }
    }, [_c("el-menu", {
      on: {
        open: _vm.handleOpen
      }
    }, _vm._l(config.list, function (item) {
      return _c("el-menu-item", {
        key: item.name,
        ref: "menu" + item.name,
        refInFor: true,
        attrs: {
          index: item.name
        },
        on: {
          click: function ($event) {
            return _vm.changeMenu(item);
          }
        }
      }, [_c("i", {
        staticClass: "el-icon-menu"
      }), _c("span", {
        attrs: {
          slot: "title"
        },
        slot: "title"
      }, [_vm._v(_vm._s(item.title))])]);
    }), 1)], 1), _c("el-container", [_c("div", {
      staticClass: "w100"
    }, [_vm.activeConfig.desc ? _c("p", {
      staticClass: "f16 w100 mt10 ml10",
      domProps: {
        innerHTML: _vm._s(_vm.activeConfig.desc)
      }
    }) : _vm._e(), _c(_vm.activeConfig.type, {
      ref: "config",
      refInFor: true,
      tag: "component",
      attrs: {
        name: _vm.name,
        group: parseInt(_vm.activeName),
        title: _vm.activeConfig.title,
        fields: _vm.activeConfig.list
      }
    })], 1)])], 1)], 1);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };