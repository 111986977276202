import config from '@/components/Config/config';
import banner from '@/components/Config/banner';
import supplier from '@/components/Config/supplier';
import invoice from '@/components/Config/invoice';
import store from '@/components/Config/store';
import company1 from '@/components/Config/company1';
import company from '@/components/Config/company';
import brand from '@/components/Config/brand';
import deviceCate from '@/components/Config/deviceCate';
import deviceModel from '@/components/Config/deviceModel';
import logistics from '@/components/Config/logistics';
import ctext from '@/components/Config/ctext';
export default {
  name: 'ConfigIndex',
  components: {
    config,
    banner,
    supplier,
    invoice,
    store,
    company1,
    company,
    brand,
    deviceCate,
    deviceModel,
    logistics,
    ctext
  },
  data() {
    return {
      activeName: '2',
      activeConfig: {
        name: undefined,
        type: undefined,
        title: undefined,
        list: []
      },
      name: '',
      configs: [{
        group: 2,
        title: '站点',
        list: [
        // { type: 'config', name: 'site_info', title: '站点信息', list: [
        //     { field: 'name', label: '站点名称' },
        //     { field: 'icp', label: '备案信息' },
        //     { field: 'tel', label: '联系电话' },
        //     { field: 'title', label: '标题', type: 'textarea' },
        //     { field: 'keyword', label: '关键词', type: 'textarea' },
        //     { field: 'desc', label: '简介', type: 'textarea' },
        //     { field: 'tj_code', label: '统计代码', type: 'textarea' },
        //   ]
        // },
        {
          type: 'supplier',
          name: 'supplier_info',
          title: '供应商'
        }, {
          type: 'brand',
          name: 'brand',
          title: '品牌'
        }, {
          type: 'deviceCate',
          name: 'deviceCate',
          title: '设备类型'
        }, {
          type: 'deviceModel',
          name: 'deviceModel',
          title: '设备型号'
        }, {
          type: 'invoice',
          name: 'invoice',
          title: '票据类型'
        }, {
          type: 'store',
          name: 'store',
          title: '仓库'
        }, {
          type: 'company1',
          name: '0',
          title: '合同公司'
        }, {
          type: 'company',
          name: '11',
          title: '客户公司'
        }, {
          type: 'logistics',
          name: 'logistics',
          title: '快递公司'
        }]
      }]
    };
  },
  mounted() {
    this.$refs['menu' + this.configs[0].list[0].name][0].$el.click();
  },
  methods: {
    handleOpen(a, b) {},
    changeMenu(menu) {
      this.activeConfig = menu;
      this.name = this.activeConfig.name;
    },
    handleClick() {
      this.$nextTick(_ => {
        for (const item of this.configs) {
          if (item.group.toString() === this.activeName) {
            this.$refs['menu' + item.list[0].name][0].$el.click();
          }
        }
      });
    }
  }
};