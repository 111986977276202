export default {
  name: 'AddressIndex',
  data() {
    return {
      operates: {
        del: {
          show: true,
          url: 'address/delete'
        },
        look: false,
        add: true,
        edit: true,
        other: [{
          title: '新增',
          click: row => this.onAdd({
            pid: row.pid
          })
        }],
        layout: false
      },
      actions: {
        tabs: [],
        index: '/address/index',
        columns: [{
          v: 'id',
          label: 'ID',
          searchType: 'match'
        }, {
          v: 'name',
          label: '名称',
          searchType: 'like'
        }, {
          v: 'sort',
          label: '排序',
          searchType: 'number'
        }]
      },
      form: {},
      nodeId: 0,
      dialogFormVisible: false,
      formAction: ''
    };
  },
  methods: {
    onLoad(tree, treeNode, resolve) {
      const pid = tree.id;
      this.$w_fun.post('/address/index', {
        id: pid
      }).then(({
        data: {
          list: {
            data
          }
        }
      }) => resolve(data.map((itm, idx) => ({
        ...itm,
        tableIndex: `${tree.tableIndex}.${idx + 1}`
      }))));
    },
    setForm({
      id,
      name,
      pid,
      sort
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        pid: {
          show: false,
          value: pid
        },
        name: {
          label: '名称',
          value: name
        },
        sort: {
          label: '排序',
          value: sort,
          formType: 'number',
          required: false,
          attrs: {
            min: 0
          }
        }
      };
    },
    onAdd(row) {
      this.setForm(row || {
        pid: 0
      });
      this.$refs.wDialogForm.visible = true;
      this.formAction = '/address/add';
    },
    onEdit(row) {
      this.setForm(row);
      this.$refs.wDialogForm.visible = true;
      this.formAction = '/address/edit';
    },
    getList() {
      this.$refs['wTable'].getList();
    }
  }
};