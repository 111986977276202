export default {
  title: 'DeviceModelSettingIndex',
  data() {
    return {
      module: 'device_model_setting',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        recycle: {
          autoLink: true
        }
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id = undefined,
      code,
      short,
      unit,
      device_cate_id,
      brand_id,
      remark,
      status
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        code: {
          label: '设备型号',
          value: code,
          max: 200
        },
        short: {
          label: '简名',
          value: short,
          max: 200,
          required: true
        },
        unit: {
          label: '单位',
          value: unit,
          max: 200,
          required: true
        },
        device_cate_id: {
          label: '设备类型',
          value: device_cate_id,
          formType: 'select',
          options: 'deviceCateSetting/select'
        },
        brand_id: {
          label: '品牌',
          value: brand_id,
          formType: 'select',
          options: 'BrandSetting/select'
        },
        remark: {
          label: '备注',
          value: remark,
          max: 200,
          required: false
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};