import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.array.push.js";
import patterns from '@/components/w/components/w-form/patterns';
import { deepClone } from '@/utils/common';
import { cloneDeep } from 'lodash';
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => ({})
    },
    prop: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableItems: [],
      rules: {},
      options: {
        private: []
      },
      template: {}
    };
  },
  methods: {
    getOption(prop, $index) {
      return this.options.private[$index] && this.options.private[$index][prop] || this.options[prop] || [];
    },
    makeOptions(optionsList = []) {
      optionsList.forEach(async ({
        prop,
        options
      }) => {
        let list = [];
        switch (this.$w_fun.typeOf(options)) {
          case 'function':
            list = await options();
            break;
          case 'string':
            list = await (await this.$w_fun.post(options)).data.list;
            break;
          case 'object':
            const {
              url = '',
              method = 'post',
              data = {},
              key = 'list'
            } = options;
            list = await (await this.$w_fun.post(url, data)).data[key];
            break;
          case 'array':
            list = options;
            break;
        }
        prop.forEach(k => {
          this.$set(this.options, k, cloneDeep(list));
          this.model[this.prop].forEach((row, $index) => {
            const {
              readonly,
              formType = 'text'
            } = this.columns.find(({
              prop
            }) => prop === k);
            if (this.readonly || readonly) {
              row[k] = this.makeReadonlyValue(k, formType, row, $index);
            }
          });
        });
        this.clearValidate(undefined, 2);
      });
    },
    async makePrivateOption(prop, privateOption) {
      this.model[this.prop].forEach(async (row, $index) => {
        const list = await privateOption(row, $index);
        this.options.private[$index] ? this.$set(this.options.private[$index], prop, list) : this.$set(this.options.private, $index, {
          [prop]: list
        });
      });
    },
    makeMethods(methods, row, $index) {
      return Object.keys(methods).reduce((pre, k) => {
        pre[k] = (...arg) => methods[k](...arg, row, $index);
        return pre;
      }, {});
    },
    makeReadonlyValue(prop, formType, row, $index) {
      switch (formType) {
        case 'radio':
        case 'checkbox':
        case 'switch':
          return this.$w_fun.valueToLabel(this.getOption(prop, $index), row[prop]);
        case 'select':
          return this.$w_fun.valueToLabel(this.getOption(prop, $index), row[prop]);
        case 'tree':
        case 'treeSelect':
        case 'treeSelectFree':
          return row[prop].map(item => this.$w_fun.findTreeParents(this.getOption(prop, $index), item, 'label').pop()).filter(i => i);
        case 'treeRadio':
        case 'treeRadioFree':
          const labels = this.$w_fun.findTreeParents(this.getOption(prop, $index), row[prop], 'label');
          return [labels.pop()];
        default:
          return row[prop];
      }
    },
    addItem() {
      const template = deepClone(this.template);
      const len = this.model[this.prop].push(template);
      this.options.private.push({});
      this.$emit('addItem', template, len - 1);
    },
    removeItem(row, $index) {
      this.model[this.prop].splice($index, 1);
      this.options.private.splice($index, 1);
      this.$emit('removeItem', row, $index);
    },
    async validate() {
      await this.$refs.formRef.validate();
    },
    clearValidate(prop, times = 0) {
      this.$refs.formRef && this.$refs.formRef.clearValidate(prop);
      times > 0 && this.$nextTick(() => this.clearValidate(prop, times - 1));
    }
  },
  watch: {
    columns: {
      immediate: true,
      handler(v) {
        this.tableItems = [];
        const optionsList = [];
        const rules = {};
        this.rules = {};
        v.forEach(column => {
          let {
            prop = '',
            show = true,
            readonly = false,
            width,
            label = '',
            defaultValue = '',
            formType = 'input',
            attrs = {},
            methods = {},
            options,
            privateOption,
            validate: {
              required = true,
              type,
              max,
              min,
              len,
              pattern,
              validator
            } = {}
          } = column;
          this.template[prop] = defaultValue;
          // 隐藏元素 不再继续处理
          if (show === false) return;

          // 处理 placeholder
          switch (formType) {
            case 'input':
            case 'textarea':
              !(attrs.maxlength > 0) && (attrs.maxlength = max);
            default:
              !attrs.placeholder && (attrs.placeholder = label);
              break;
          }

          // 处理 tableItems
          this.tableItems.push({
            readonly,
            required,
            width,
            label,
            prop,
            formType,
            attrs,
            methods
          });

          // 处理 options
          if (options && (!this.options[prop] || !this.options[prop].length)) {
            const index = optionsList.findIndex(i => i.options === options);
            index === -1 ? optionsList.push({
              prop: [prop],
              options
            }) : optionsList[index].prop.push(prop);
          }
          // 处理 privateOption
          else if (this.$w_fun.typeOf(privateOption) === 'function') {
            this.makePrivateOption(prop, privateOption);
          }

          // 只读表单 不再继续处理
          if (this.readonly || readonly) return delete this.template[prop];

          // 处理表单验证
          const rule = [];
          // 验证触发时机
          let trigger = 'blur';
          switch (formType) {
            case 'number':
              type = 'number';
              break;
            case 'checkbox':
            case 'address':
            case 'tree':
            case 'treeRadio':
            case 'treeRadioFree':
            case 'treeSelect':
            case 'treeSelectFree':
              type = 'array';
            case 'radio':
            case 'select':
            case 'switch':
            case 'timeSelect':
            case 'timePicker':
            case 'datePicker':
              trigger = 'change';
              break;
          }
          if (required !== false) rule.push({
            required: true,
            message: '不能为空',
            trigger
          });
          switch (type) {
            case 'number':
              if (![max, min].includes(undefined)) {
                rule.push({
                  type,
                  validator: (r, v) => typeof v === 'number' && (v > max || v < min) ? Promise.reject(`应在${min}-${max}之间`) : Promise.resolve(),
                  trigger
                });
              } else if (max !== undefined) {
                rule.push({
                  type,
                  validator: (r, v) => typeof v === 'number' && v > max ? Promise.reject(`不得大于${max}`) : Promise.resolve(),
                  trigger
                });
              } else if (min !== undefined) {
                rule.push({
                  type,
                  validator: (r, v) => typeof v === 'number' && v < min ? Promise.reject(`不得小于${min}`) : Promise.resolve(),
                  trigger
                });
              }
              break;
            case 'array':
              len > 0 && rule.push({
                type,
                len,
                message: `应选择${len}项`,
                trigger
              });
              if (max > 0 && min > 0) {
                rule.push({
                  type,
                  max,
                  min,
                  message: `应选择${min}-${max}项`,
                  trigger
                });
              } else if (max > 0) {
                rule.push({
                  type,
                  max,
                  message: `不得超过${max}项`,
                  trigger
                });
              } else if (min > 0) {
                rule.push({
                  type,
                  min,
                  message: `不得少于${min}项`,
                  trigger
                });
              }
              break;
            default:
              len > 0 && rule.push({
                len,
                message: `应为${len}个字符`,
                trigger
              });
              if (max > 0 && min > 0) {
                rule.push({
                  max,
                  min,
                  message: `应为${min}-${max}个字符`,
                  trigger
                });
              } else if (max > 0) {
                rule.push({
                  max,
                  message: `不得超过${max}个字符`,
                  trigger
                });
              } else if (min > 0) {
                rule.push({
                  min,
                  message: `不得少于${min}个字符`,
                  trigger
                });
              }
              break;
          }
          if (pattern) {
            if (patterns[pattern]) {
              const {
                reg,
                msg = '格式错误'
              } = patterns[pattern];
              rule.push({
                pattern: reg || patterns[pattern],
                message: `${msg}`,
                trigger
              });
            } else {
              rule.push({
                pattern,
                message: `格式错误`,
                trigger
              });
            }
          }
          switch (this.$w_fun.typeOf(validator)) {
            case 'function':
              rule.push({
                validator: (r, v) => validator(r, v, this.model, this.options),
                trigger
              });
              break;
            case 'object':
              rule.push({
                ...validator,
                validator: (r, v) => validator.validator(r, v, this.model, this.options)
              });
              break;
          }
          rules[prop] = rule;
        });
        this.makeOptions(optionsList);
        this.rules = rules;
      }
    }
  }
};