var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100 p25"
  }, [_c("el-form", {
    ref: "dataForm",
    attrs: {
      model: _vm.model,
      "label-suffix": "：",
      "label-position": "right",
      "label-width": "180px"
    }
  }, _vm._l(_vm.fields, function (item) {
    return _c("el-form-item", {
      key: item.field,
      attrs: {
        label: item.label
      }
    }, [item.type === "switch" ? _c("el-switch", {
      attrs: {
        "active-value": 1,
        "inactive-value": 0,
        "active-color": "#13ce66",
        "inactive-color": "#ff4949"
      },
      model: {
        value: _vm.model[item.field],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.field, $$v);
        },
        expression: "model[item.field]"
      }
    }) : _c("el-input", {
      attrs: {
        type: item.type || "input",
        autosize: ""
      },
      model: {
        value: _vm.model[item.field],
        callback: function ($$v) {
          _vm.$set(_vm.model, item.field, $$v);
        },
        expression: "model[item.field]"
      }
    })], 1);
  }), 1), _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("保存")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };