export default {
  title: 'ProofIndex',
  data() {
    return {
      module: 'market_proof',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        export: true
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id,
      solely_id,
      type,
      money,
      file,
      status
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        solely_id: {
          label: '入库单或者出库单的id',
          value: solely_id,
          formType: 'number'
        },
        type: {
          label: '1=付款凭证，2=收款凭证',
          value: type,
          formType: 'number'
        },
        money: {
          label: '金额',
          value: money,
          formType: 'number'
        },
        file: {
          label: '凭证文件',
          value: file
        },
        status: {
          label: '1=正常使用，0=作废',
          value: status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};