export default {
  title: 'SalesSettingStoreIndex',
  data() {
    return {
      module: 'sales_setting_store',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        recycle: {
          autoLink: true
        }
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id = undefined,
      mobile,
      title,
      address,
      status,
      user_id
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '仓库名称',
          value: title,
          max: 60
        },
        mobile: {
          label: '联系电话',
          value: mobile,
          max: 11
        },
        address: {
          label: '地址',
          value: address,
          max: 200
        },
        person_id: {
          label: '责任人',
          value: user_id,
          formType: 'treeSelect',
          options: 'role/all',
          required: false
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};