export default {
  name: 'flow',
  data() {
    return {
      module: 'sales_setting_flow',
      operates: {
        edit: true,
        del: true,
        look: false,
        add: true
      },
      actions: {
        tabs: [{
          label: '销售出库单',
          name: 0
        }, {
          label: '采购入库单',
          name: 1
        }, {
          label: '付款单',
          name: 2
        }, {
          label: '收款单',
          name: 3
        }, {
          label: '出库单修改',
          name: 4
        }, {
          label: '入库单修改',
          name: 5
        }]
      },
      formAction: '',
      form: {},
      currentTab: ''
    };
  },
  methods: {
    getList(row) {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id,
      title,
      is_way,
      examine_user = [],
      cc_admin_id = [],
      status = 1
    }) {
      this.form = {
        type: {
          show: false,
          value: this.currentTab
        },
        id: {
          show: false,
          value: id
        },
        title: {
          label: '流程名称',
          value: title
        },
        is_way: {
          label: '审核方式',
          value: is_way,
          formType: 'select',
          options: 'SalesSettingFlow/get_setting'
        },
        examine_user: {
          label: '审批人',
          value: examine_user,
          type: 'array',
          formType: 'treeSelect',
          options: 'role/all'
        },
        cc_admin_id: {
          label: '抄送人',
          value: cc_admin_id,
          type: 'array',
          formType: 'treeSelect',
          options: 'role/all',
          required: false
        },
        status: {
          label: '状态',
          value: status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    onChangeTab(v) {
      this.currentTab = v;
    }
  }
};