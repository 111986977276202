import { post } from '@/utils/request';
export default {
  name: 'FinanceDialog',
  data() {
    return {
      readonly: false,
      title: '',
      action: '',
      form: {}
    };
  },
  methods: {
    async setForm({
      id,
      market,
      ware_id,
      s_id,
      settlement_company_id,
      company_id,
      accounts_payable,
      abstract
    }, readonly) {
      const device = readonly ? (await post('/finance/getDeviceInfo', {
        id
      })).data.list : [];
      const form = {
        type: {
          value: 2,
          show: false
        },
        ware_id: {
          readonly: !!id,
          value: id ? market.id_text : ware_id,
          label: '销售单',
          formType: 'select',
          options: '/market/finance_ware',
          methods: {
            change: async v => {
              const {
                model,
                options
              } = this.$refs.dialogForm.$refs.form;
              model.s_id = '';
              model.settlement_company_id = '';
              model.company_id = '';
              model.accounts_payable = '';
              model.abstract = '';
              if (!v) return;
              const current = options.ware_id.find(({
                value
              }) => value === v);
              if (!current) return;
              options.settlement_company_id = (await post('/SalesSettingCompany/settlement', {
                id: current.supplier_id,
                type: 2
              })).data.list;
              model.s_id = current.supplier_id;
              model.settlement_company_id = current.settlement_company_id;
              model.company_id = current.company_id;
              model.accounts_payable = current.total_cost;
              model.abstract = current.abstract;
            }
          }
        },
        s_id: {
          readonly: !!id,
          label: '客户单位',
          value: s_id,
          formType: 'select',
          options: '/SalesSettingCompany/select?type=11',
          attrs: {
            disabled: true
          }
        },
        settlement_company_id: {
          label: '结算单位',
          value: settlement_company_id,
          formType: 'select',
          options: s_id ? {
            url: '/SalesSettingCompany/settlement',
            data: {
              id: s_id,
              type: 2
            }
          } : []
        },
        company_id: {
          label: '合同公司',
          value: company_id,
          formType: 'select',
          options: '/SalesSettingCompany/select?type=0'
        },
        accounts_payable: {
          label: '应收账款',
          value: accounts_payable,
          formType: 'number'
        },
        abstract: {
          label: '摘要',
          value: abstract,
          formType: 'textarea',
          required: false
        },
        device: {
          show: readonly,
          readonly: true,
          label: '详情',
          value: device,
          formType: 'deviceSlot'
        }
      };
      if (id) {
        form.id = {
          show: false,
          value: id
        };
        this.title = '编辑收款单';
        this.action = '/finance/edit';
      } else {
        this.title = '生成收款单';
        this.action = '/finance/add';
      }
      this.form = form;
    },
    async open(data = {}, readonly = false) {
      this.readonly = readonly;
      await this.setForm(data, readonly);
      this.$refs.dialogForm.visible = true;
    }
  }
};