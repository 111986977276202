var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "tableRef",
    attrs: {
      actions: _vm.actions,
      operates: _vm.operates,
      k: "",
      kPlaceholder: "请输入采购单编号"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };