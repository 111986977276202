export default {
  name: 'logisticsDialog',
  data() {
    return {
      title: '',
      form: {},
      action: ''
    };
  },
  methods: {
    setForm({
      id
    }) {
      const form = {
        id: {
          show: false,
          value: id
        },
        fast_mail: {
          value: '',
          show: false
        },
        //物流公司
        logistics_id: {
          label: '快递公司',
          value: '',
          formType: 'select',
          options: '/logistics_setting/select'
        },
        logistics_num: {
          label: '物流单号',
          value: '',
          formType: 'input'
        }
      };
      if (id) {
        form.id = {
          show: false,
          value: id
        };
        this.title = '物流信息';
        this.action = '/ware/logistics';
      }
      this.form = form;
    },
    open(data) {
      this.$refs.dialogForm.visible = true;
      this.$nextTick(() => this.setForm(data));
    }
  }
};