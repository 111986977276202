var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      operates: _vm.operates,
      module: _vm.module
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    },
    scopedSlots: _vm._u([{
      key: "accountSlot",
      fn: function ({
        row
      }) {
        return [row.account && row.account.length ? _vm._l(row.account, function (item) {
          return _c("div", {
            key: item.id,
            staticClass: "omit"
          }, [_vm._v(" 单位名称：" + _vm._s(item.account_name) + " 结算账户：" + _vm._s(item.account) + " ")]);
        }) : [_vm._v("-")]];
      }
    }])
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "添加/编辑",
      form: _vm.form,
      action: _vm.formAction,
      width: "60%"
    },
    on: {
      done: _vm.getList
    },
    scopedSlots: _vm._u([{
      key: "accountSlot",
      fn: function ({
        item,
        model
      }) {
        return [_c("TableForm", {
          ref: "tableFormRef",
          attrs: {
            model: model,
            prop: "account",
            columns: _vm.tableFormColumns
          }
        })];
      }
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };