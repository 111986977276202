import Edit from './dialog/edit.vue';
import UploadInvoice from './dialog/uploadInvoice.vue';
export default {
  title: 'PaymentIndex',
  components: {
    Edit,
    UploadInvoice
  },
  data() {
    return {
      module: 'payment',
      operates: {
        del: false,
        look: true,
        edit: {
          disabled: row => row.ware.pay_examine >= 2
        },
        export: true,
        other: [{
          title: '上传发票',
          type: 'primary',
          disabled: row => row.ware.pay_examine < 2 || row.ware.pay_examine >= 4,
          click: row => this.$refs.uploadInvoiceRef.open(row)
        }]
      }
    };
  },
  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    onEdit(row = {}, readonly = false) {
      this.$refs.editRef.open(row, readonly);
    }
  }
};