import { post } from '@/utils/request';
import { add, subtract, multiply } from 'lodash';
import contractDialog from './dialog/contractDialog';
import invoiceDialog from './dialog/invoice';
import Edit from './dialog/edit.vue';
import CollectionEdit from '../collection/dialog/edit.vue';
import UploadInvoice from '../collection/dialog/uploadInvoice.vue';
import Logistics from './dialog/logistics';
import Outbound from './dialog/outbound.vue';
export default {
  components: {
    Edit,
    contractDialog,
    Logistics,
    invoiceDialog,
    CollectionEdit,
    UploadInvoice,
    Outbound
  },
  title: 'MarketIndex',
  data() {
    return {
      module: 'market',
      operates: {
        look: true,
        del: false,
        add: true,
        edit: {
          // disabled: row => row.status > 5
        },
        export: true,
        other: [{
          title: '撤回',
          show: row => row.status === 10,
          click: (row, $index) => this.withdraw(row, $index)
        }, {
          p: 'market/upload_contract',
          title: '上传合同',
          show: row => row.status === 10,
          click: (row, $index) => this.contract(row, $index)
        }, {
          p: 'market/collection',
          title: '收款单',
          show: row => row.status >= 15 && row.status <= 40 && row.pay_examine < 2,
          click: row => {
            if (row.finance) {
              const {
                id,
                ware_id,
                s_id,
                settlement_company_id,
                company_id,
                accounts_payable,
                abstract
              } = row.finance;
              this.$refs.collectionEditRef.open({
                id,
                market: {
                  id_text: row.id_text
                },
                ware_id,
                s_id,
                settlement_company_id,
                company_id,
                accounts_payable,
                abstract
              }, false);
            } else {
              const {
                id: ware_id,
                supplier_id: s_id,
                settlement_company_id,
                company_id,
                total_cost: accounts_payable,
                abstract
              } = row;
              this.$refs.collectionEditRef.open({
                ware_id,
                s_id,
                settlement_company_id,
                company_id,
                accounts_payable,
                abstract
              }, false);
            }
          }
        }, {
          p: 'market/upload_invoice',
          title: '上传发票',
          show: row => row.status !== 45 && row.pay_examine >= 2 && row.pay_examine < 4,
          click: row => this.$refs.uploadInvoiceRef.open({
            id: row.finance.id,
            market: {
              id: row.id
            }
          })
        }, {
          p: 'market/out_bound',
          title: '出库',
          show: row => [15, 35].includes(row.status),
          click: (row, $index) => {
            this.currentIndex = $index;
            this.$refs.outboundRef.toggleVisible(true);
          }
        }, {
          p: 'market/setLogistics',
          title: '物流信息',
          show: row => row.status >= 35 && row.status <= 40,
          click: row => this.$refs.logisticsRef.open(row, false)
        }]
      },
      data: [],
      currentIndex: undefined
    };
  },
  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    onGetList(list) {
      this.data = list.list.data;
    },
    onEdit(row = {}, readonly = false) {
      this.$refs.editRef.open(row, readonly);
    },
    contract(row, index) {
      this.$refs.contract_dialog.open(row, false);
    },
    async withdraw(row, index) {
      const {
        value
      } = await this.$prompt('请填写撤回原因', '提示', {
        confirmButtonText: '提交',
        cancelButtonText: '取消',
        type: 'warning',
        inputValidator: v => !!v && !!v.trim() || '撤回原因不能为空',
        inputErrorMessage: '撤回原因不能为空'
      });
      await post('/market/withdraw', {
        id: row.id,
        reason: value
      });
      this.getList();
    },
    invoice(row, index) {
      this.$refs.invoice_dialog.open(row, false);
    }
  }
};