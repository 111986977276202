var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "dialogFormRef",
    attrs: {
      readonly: _vm.readonly,
      title: _vm.title,
      form: _vm.form,
      action: _vm.action,
      width: "100%",
      "item-width": "45%",
      beforeSubmit: _vm.beforeSubmit
    },
    on: {
      done: v => _vm.$emit("done", v)
    },
    scopedSlots: _vm._u([{
      key: "productSlot",
      fn: function ({
        item,
        model
      }) {
        return [_c("TableForm", {
          ref: "tableFormRef",
          attrs: {
            model: model,
            prop: "product",
            columns: _vm.tableFormColumns,
            readonly: _vm.readonly
          },
          on: {
            addItem: _vm.makeAmount,
            removeItem: _vm.makeAmount
          }
        })];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };