var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.extActiveName,
      callback: function ($$v) {
        _vm.extActiveName = $$v;
      },
      expression: "extActiveName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "SmartBuy",
      name: "sb"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: "EasyBuy",
      name: "eb"
    }
  })], 1), _c("el-tabs", {
    attrs: {
      type: "card"
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "未授权",
      name: "0"
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.notAuthConfig,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "来源",
      width: "180",
      prop: "title"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "淘宝",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "PID",
      "min-width": "240",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          model: {
            value: scope.row.pid,
            callback: function ($$v) {
              _vm.$set(scope.row, "pid", $$v);
            },
            expression: "scope.row.pid"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "AuthId",
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          model: {
            value: scope.row.authId,
            callback: function ($$v) {
              _vm.$set(scope.row, "authId", $$v);
            },
            expression: "scope.row.authId"
          }
        })];
      }
    }])
  })], 1), _c("el-table-column", {
    attrs: {
      label: "京东",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "unionId",
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          model: {
            value: scope.row.unionId,
            callback: function ($$v) {
              _vm.$set(scope.row, "unionId", $$v);
            },
            expression: "scope.row.unionId"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "siteId",
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          model: {
            value: scope.row.siteId,
            callback: function ($$v) {
              _vm.$set(scope.row, "siteId", $$v);
            },
            expression: "scope.row.siteId"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "positionId",
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          model: {
            value: scope.row.positionId,
            callback: function ($$v) {
              _vm.$set(scope.row, "positionId", $$v);
            },
            expression: "scope.row.positionId"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c("el-tab-pane", {
    attrs: {
      label: "已授权",
      name: "1"
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.authConfig,
      border: "",
      fit: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "来源",
      width: "180",
      prop: "title"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "淘宝",
      align: "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "PID",
      "min-width": "240",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          model: {
            value: scope.row.pid,
            callback: function ($$v) {
              _vm.$set(scope.row, "pid", $$v);
            },
            expression: "scope.row.pid"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "AuthId",
      width: "180",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-input", {
          model: {
            value: scope.row.authId,
            callback: function ($$v) {
              _vm.$set(scope.row, "authId", $$v);
            },
            expression: "scope.row.authId"
          }
        })];
      }
    }])
  })], 1)], 1)], 1)], 1), _c("div", {
    staticClass: "tc fixed w-100 left-0 right-0 bottom-0 bg-gray border-gray-top p5 z100"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("保存")]), _c("el-button", {
    on: {
      click: function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };