var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "w100 p25"
  }, [_c("el-form", {
    ref: "dataForm",
    attrs: {
      model: _vm.model,
      "label-suffix": "：",
      "label-position": "right",
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.title
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入",
      type: "textarea",
      autosize: {
        minRows: 4,
        maxRows: 12
      }
    },
    model: {
      value: _vm.model.value,
      callback: function ($$v) {
        _vm.$set(_vm.model, "value", $$v);
      },
      expression: "model.value"
    }
  })], 1)], 1), _c("el-button", {
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.handleSave
    }
  }, [_vm._v("保存")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };