var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "dialogFormRef",
    attrs: {
      title: "修改SN号",
      form: _vm.form,
      action: _vm.action
    },
    on: {
      done: v => _vm.$emit("done", v)
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };