import { post } from '@/utils/request';
export default {
  name: 'FinanceDialog',
  data() {
    return {
      readonly: false,
      title: '',
      action: '',
      form: {}
    };
  },
  methods: {
    async setForm({
      id,
      ware,
      ware_id,
      s_id,
      settlement_company_id,
      company_id,
      accounts_payable,
      abstract
    }, readonly) {
      const device = readonly ? (await post('/finance/getDeviceInfo', {
        id
      })).data.list : [];
      const form = {
        type: {
          value: 1,
          show: false
        },
        ware_id: {
          readonly: !!id,
          value: id ? ware.id_text : ware_id,
          label: '采购单',
          formType: 'select',
          options: '/ware/finance_ware',
          methods: {
            change: async v => {
              const {
                model,
                options
              } = this.$refs.dialogForm.$refs.form;
              model.s_id = '';
              model.settlement_company_id = '';
              model.company_id = '';
              model.accounts_payable = '';
              model.abstract = '';
              if (!v) return;
              const current = options.ware_id.find(({
                value
              }) => value === v);
              if (!current) return;
              options.settlement_company_id = (await post('/SalesSettingSupplier/settlement', {
                id: current.supplier_id,
                type: 1
              })).data.list;
              model.s_id = current.supplier_id;
              model.settlement_company_id = current.settlement_company_id;
              model.company_id = current.company_id;
              model.accounts_payable = current.accounts_payable;
              model.abstract = current.abstract;
            }
          }
        },
        s_id: {
          readonly: !!id,
          label: '供应商',
          value: s_id,
          formType: 'select',
          options: '/sales_setting_supplier/select',
          attrs: {
            disabled: true
          }
        },
        settlement_company_id: {
          label: '结算单位',
          value: settlement_company_id,
          formType: 'select',
          options: s_id ? {
            url: '/SalesSettingSupplier/settlement',
            data: {
              id: s_id,
              type: 1
            }
          } : []
        },
        company_id: {
          label: '合同公司',
          value: company_id,
          formType: 'select',
          options: '/SalesSettingCompany/select?type=0'
        },
        accounts_payable: {
          label: '应付账款',
          value: accounts_payable,
          formType: 'number'
        },
        abstract: {
          label: '摘要',
          value: abstract,
          formType: 'textarea',
          required: false
        },
        device: {
          show: readonly,
          readonly: true,
          label: '详情',
          value: device,
          formType: 'deviceSlot'
        }
      };
      if (id) {
        form.id = {
          show: false,
          value: id
        };
        this.title = readonly ? '详情' : '编辑';
        this.action = '/finance/edit';
      } else {
        this.title = '新增';
        this.action = '/finance/add';
      }
      this.form = form;
    },
    async open(data = {}, readonly = false) {
      await this.setForm(data, readonly);
      this.$refs.dialogForm.visible = true;
      this.readonly = readonly;
    }
  }
};