var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "wTable",
    attrs: {
      actions: _vm.actions,
      operates: _vm.operates,
      "tree-props": {
        children: "children",
        hasChildren: "has_children"
      },
      lazy: true,
      load: _vm.onLoad
    },
    on: {
      add: _vm.onAdd,
      edit: _vm.onEdit
    }
  }), _c("w-dialog-form", {
    ref: "wDialogForm",
    attrs: {
      title: "地址",
      form: _vm.form,
      action: _vm.formAction,
      width: "60%"
    },
    on: {
      done: _vm.getList
    },
    model: {
      value: _vm.dialogFormVisible,
      callback: function ($$v) {
        _vm.dialogFormVisible = $$v;
      },
      expression: "dialogFormVisible"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };