export default {
  data() {
    return {
      action: '/sn/edit',
      form: {}
    };
  },
  methods: {
    async open(row) {
      this.setForm(row);
      await this.$nextTick();
      this.$refs.dialogFormRef.visible = true;
    },
    setForm({
      id,
      sn_number
    }) {
      const form = {
        id: {
          show: false,
          value: id
        },
        sn_number: {
          label: 'SN',
          value: sn_number
        }
      };
      this.form = form;
    }
  }
};