export default {
  title: 'SalesSettingCompanyIndex',
  data() {
    return {
      module: 'sales_setting_company',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        recycle: {
          autoLink: true
        }
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id = undefined,
      mobile,
      title,
      short_title,
      address,
      status,
      remark,
      account
    }) {
      this.form = {
        id: {
          value: id,
          show: false
        },
        title: {
          label: '公司名称',
          value: title,
          max: 60
        },
        short_title: {
          label: '公司简称',
          value: short_title,
          max: 200
        },
        mobile: {
          label: '联系电话',
          value: mobile,
          max: 11
        },
        address: {
          label: '地址',
          value: address,
          max: 200
        },
        account: {
          label: '银行账号',
          value: account,
          max: 200
        },
        type: {
          show: false,
          value: 0
        },
        remark: {
          label: '备注',
          value: remark,
          max: 200,
          required: false
        },
        status: {
          label: '状态',
          value: status === undefined ? 1 : status,
          formType: 'status'
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};