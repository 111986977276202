export default {
  title: 'MarketContract',
  data() {
    return {
      module: 'market_contract',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: true,
        export: true
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    setForm({
      id,
      contract,
      contract_code
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        contract_code: {
          label: '合同编码',
          value: contract_code,
          max: 200
        },
        contract: {
          label: '合同',
          value: contract,
          formType: 'upload',
          attrs: {
            accept: '.pdf',
            tip: '只能上传 pdf，最大20M',
            limit: 1,
            data: {
              type: 'static'
            }
          }
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};