export default {
  title: 'WareProductIndex',
  data() {
    return {
      module: 'market_product',
      operates: {
        del: false,
        look: false,
        add: false,
        edit: false,
        export: true
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs.tableRef.getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id,
      ware_id,
      type,
      code,
      product_name,
      store_id,
      unit,
      num,
      price,
      money,
      bill_id,
      status,
      remark
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        ware_id: {
          label: '入库单id',
          value: ware_id,
          formType: 'number'
        },
        type: {
          label: '1入库单2出库单',
          value: type,
          formType: 'number'
        },
        code: {
          label: '编码',
          value: code
        },
        product_name: {
          label: '名称',
          value: product_name
        },
        store_id: {
          label: '仓库id',
          value: store_id,
          formType: 'number'
        },
        unit: {
          label: '单位',
          value: unit
        },
        num: {
          label: '数量',
          value: num,
          formType: 'number'
        },
        price: {
          label: '价格',
          value: price,
          formType: 'number'
        },
        money: {
          label: '总价',
          value: money,
          formType: 'number'
        },
        bill_id: {
          label: '发票类型',
          value: bill_id,
          formType: 'number'
        },
        status: {
          label: '',
          value: status,
          formType: 'status'
        },
        remark: {
          label: '备注',
          value: remark
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getTable() {
      return this.$refs.tableRef.$refs.table;
    },
    makeLen(index, cb) {
      let len = 0;
      this.getTable().data.slice(index).every(row => cb(row) && ++len);
      return len;
    },
    spanMethods({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      const list = this.getTable().data;
      let [rowspan, colspan] = [1, 1];
      if (['market.created_at', 'market.id_text', 'market.admin_id', 'contract_code', 'market.total_cost', 'invoice_money', 'invoice_list'].includes(column.property)) {
        rowspan = rowIndex === 0 || row.market.id_text !== list[rowIndex - 1].market.id_text ? this.makeLen(rowIndex, ({
          market: {
            id_text
          }
        }) => id_text === row.market.id_text) : 0;
      }
      return {
        rowspan,
        colspan
      };
    }
  }
};