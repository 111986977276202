export default {
  name: 'logisticsDialog',
  data() {
    return {
      title: '物流信息',
      action: '/market/setLogistics',
      form: {}
    };
  },
  methods: {
    setForm({
      id
    }) {
      const form = {
        id: {
          show: false,
          value: id
        },
        logistics_num: {
          label: '物流单号',
          value: ''
        },
        money: {
          label: '运费',
          value: '',
          type: 'number',
          formType: 'number',
          min: 0,
          pattern: 'price'
        }
      };
      this.form = form;
    },
    open(data) {
      this.$refs.dialogFormRef.visible = true;
      this.$nextTick(() => this.setForm(data));
    }
  }
};