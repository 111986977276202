var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "formRef",
    attrs: {
      model: _vm.model,
      "validate-on-rule-change": false
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.model[_vm.prop],
      stripe: "",
      border: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50",
      align: "center",
      fixed: "left"
    }
  }), _vm._l(_vm.tableItems, function (item) {
    return _c("el-table-column", {
      attrs: {
        label: item.label,
        prop: item.prop,
        "min-width": item.width ? item.width + "px" : undefined
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function () {
          return [item.required && !_vm.readonly ? _c("span", {
            staticClass: "red"
          }, [_vm._v("*")]) : _vm._e(), _vm._v(" " + _vm._s(item.label) + " ")];
        },
        proxy: true
      }, {
        key: "default",
        fn: function ({
          row,
          $index
        }) {
          return [_c("el-form-item", {
            staticStyle: {
              "word-wrap": "break-word",
              "word-break": "break-all"
            },
            attrs: {
              prop: _vm.prop + "." + $index + "." + item.prop,
              rules: _vm.rules[item.prop]
            }
          }, [_vm.readonly || item.readonly ? [["checkbox", "tree", "treeRadio", "treeRadioFree", "treeSelect", "treeSelectFree"].includes(item.formType) ? [_c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.model[item.prop].join("；") || "-")
            }
          })] : [_vm._v(" " + _vm._s(row[item.prop] || "-") + " ")]] : [["input", "textarea"].includes(item.formType) ? _c("w-input", _vm._g(_vm._b({
            attrs: {
              type: item.formType
            },
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "w-input", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "cascader" ? _c("el-cascader", _vm._g(_vm._b({
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "el-cascader", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "number" ? _c("w-input", _vm._g(_vm._b({
            attrs: {
              type: "number"
            },
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, _vm._n($$v));
              },
              expression: "row[item.prop]"
            }
          }, "w-input", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "radio" ? _c("el-radio-group", _vm._g(_vm._b({
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "el-radio-group", item.attrs, false), _vm.makeMethods(item.methods, row, $index)), _vm._l(_vm.getOption(item.prop, $index), function (itm, idx) {
            return _c("el-radio", _vm._b({
              key: idx,
              attrs: {
                label: itm.value
              }
            }, "el-radio", itm, false), [_vm._v(" " + _vm._s(itm.label) + " ")]);
          }), 1) : item.formType === "checkbox" ? _c("el-checkbox-group", _vm._g(_vm._b({
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "el-checkbox-group", item.attrs, false), _vm.makeMethods(item.methods, row, $index)), _vm._l(_vm.getOption(item.prop, $index), function (itm, idx) {
            return _c("el-checkbox", _vm._b({
              key: idx,
              attrs: {
                label: itm.value
              }
            }, "el-checkbox", itm, false), [_vm._v(" " + _vm._s(itm.label) + " ")]);
          }), 1) : item.formType === "select" ? _c("w-select", _vm._g(_vm._b({
            attrs: {
              data: _vm.getOption(item.prop, $index)
            },
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "w-select", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "status" || item.formType === "boolean" ? _c("el-radio-group", _vm._g(_vm._b({
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "el-radio-group", item.attrs, false), _vm.makeMethods(item.methods, row, $index)), [_c("el-radio", {
            attrs: {
              label: 1
            }
          }, [_vm._v(_vm._s(item.formType === "status" ? "正常" : "是"))]), _c("el-radio", {
            attrs: {
              label: 0
            }
          }, [_vm._v(_vm._s(item.formType === "status" ? "禁用" : "否"))])], 1) : ["tree", "treeRadio", "treeRadioFree", "treeSelect", "treeSelectFree"].includes(item.formType) ? _c(item.formType === "tree" ? "w-tree" : "w-input-tree", _vm._g(_vm._b({
            ref: `${item.prop}Tree`,
            refInFor: true,
            tag: "component",
            attrs: {
              data: _vm.getOption(item.prop, $index),
              treeType: item.formType
            },
            on: {
              change: (v, nodes, isFirst) => !isFirst && _vm.validateField(_vm.prop + "." + $index + "." + item.prop)
            },
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "component", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "switch" ? _c("el-switch", _vm._g(_vm._b({
            attrs: {
              "active-text": _vm.options[item.prop] && _vm.options[item.prop][1].label,
              "active-value": _vm.options[item.prop] && _vm.options[item.prop][1].value,
              "inactive-text": _vm.options[item.prop] && _vm.options[item.prop][0].label,
              "inactive-value": _vm.options[item.prop] && _vm.options[item.prop][0].value
            },
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "el-switch", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "timeSelect" ? _c("el-time-select", _vm._g(_vm._b({
            staticClass: "w100",
            attrs: {
              "picker-options": item.attrs.pickerOptions,
              "value-format": item.attrs.valueFormat || "HH:mm",
              format: item.attrs.format || "HH:mm"
            },
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "el-time-select", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "timePicker" ? _c("el-time-picker", _vm._g(_vm._b({
            staticClass: "w100",
            attrs: {
              "value-format": item.attrs.valueFormat || "HH:mm",
              format: item.attrs.format || "HH:mm"
            },
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "el-time-picker", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : item.formType === "datePicker" ? _c("w-date-picker", _vm._g(_vm._b({
            model: {
              value: row[item.prop],
              callback: function ($$v) {
                _vm.$set(row, item.prop, $$v);
              },
              expression: "row[item.prop]"
            }
          }, "w-date-picker", item.attrs, false), _vm.makeMethods(item.methods, row, $index))) : _vm._e()]], 2)];
        }
      }], null, true)
    });
  }), !_vm.readonly ? _c("el-table-column", {
    attrs: {
      width: "60",
      align: "center",
      fixed: "right"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: _vm.addItem
          }
        }, [_vm._v(" 新增 ")])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function ({
        row,
        $index
      }) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function ($event) {
              return _vm.removeItem(row, $index);
            }
          }
        }, [_vm._v(" 删除 ")])];
      }
    }], null, false, 3022975083)
  }) : _vm._e()], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };