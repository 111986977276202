export default {
  name: 'AdminLogIndex',
  data() {
    return {
      module: 'admin_log',
      operates: {
        del: false,
        look: false,
        edit: false,
        add: false,
        other: [{
          title: '复制',
          click: (row, $index) => this.copyRowInfo(row)
        }]
      }
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    copyRowInfo(item) {
      this.$w_fun.copy(item);
      this.$message.success('复制成功');
    }
  }
};