var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("w-dialog-form", {
    ref: "dialogFormRef",
    attrs: {
      title: _vm.title,
      action: _vm.action,
      form: _vm.form,
      width: "900px",
      beforeSubmit: _vm.beforeSubmit
    },
    on: {
      done: v => _vm.$emit("done", v)
    },
    scopedSlots: _vm._u([{
      key: "dataSlot",
      fn: function ({
        item,
        model
      }) {
        return [_c("el-table", {
          attrs: {
            data: model[item.prop],
            stripe: "",
            border: "",
            size: "mini"
          }
        }, [_c("el-table-column", {
          attrs: {
            type: "index",
            label: "序号",
            align: "center"
          }
        }), _c("el-table-column", {
          attrs: {
            label: "型号",
            prop: "code"
          }
        }), _c("el-table-column", {
          attrs: {
            label: "销售数量",
            prop: "num"
          }
        }), _c("el-table-column", {
          attrs: {
            label: "总金额",
            prop: "money"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              row
            }) {
              return [_vm._v(" " + _vm._s(row.money) + "元 ")];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            label: "开出数量"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function ({
              row
            }) {
              return [_c("el-input", {
                attrs: {
                  type: "number",
                  placeholder: "请输入开出数量"
                },
                model: {
                  value: row.number,
                  callback: function ($$v) {
                    _vm.$set(row, "number", _vm._n($$v));
                  },
                  expression: "row.number"
                }
              })];
            }
          }], null, true)
        })], 1)];
      }
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };