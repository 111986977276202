export default {
  name: 'InvoiceDialog',
  data() {
    return {
      title: '',
      form: {},
      action: ''
    };
  },
  methods: {
    setForm({
      id
    }) {
      const form = {
        id: {
          show: false,
          value: id
        },
        type: {
          value: 1,
          show: false
        },
        money: {
          label: '发票金额',
          value: 0,
          formType: 'number'
        },
        file: {
          label: '凭证文件',
          value: '',
          formType: 'upload',
          attrs: {
            accept: '.png,.jpg,.gif,.pdf'
          }
        }
      };
      if (id) {
        form.id = {
          show: false,
          value: id
        };
        this.title = '上传发票';
        this.action = '/ware/upload_invoice';
      }
      this.form = form;
    },
    open(data) {
      this.$refs.dialogForm.visible = true;
      this.$nextTick(() => this.setForm(data));
    }
  }
};