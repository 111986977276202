var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("w-tabs-table", {
    ref: "tableRef",
    attrs: {
      operates: _vm.operates,
      module: _vm.module
    },
    on: {
      add: _vm.onEdit,
      edit: row => _vm.onEdit(row, false),
      look: row => _vm.onEdit(row, true),
      getList: _vm.onGetList
    },
    scopedSlots: _vm._u([{
      key: "logisticsSlot",
      fn: function ({
        row,
        $index
      }) {
        return [row.logistics.length === 0 ? [_vm._v("-")] : _vm._l(row.logistics, function (item) {
          return _c("div", {
            staticClass: "omit"
          }, [_vm._v(" 金额：" + _vm._s(item.money) + " 物流单号：" + _vm._s(item.logistics_num) + " ")]);
        })];
      }
    }])
  }), _c("contract-dialog", {
    ref: "contract_dialog",
    on: {
      done: _vm.getList
    }
  }), _c("invoice-dialog", {
    ref: "invoice_dialog",
    on: {
      done: _vm.getList
    }
  }), _c("Edit", {
    ref: "editRef",
    on: {
      done: _vm.getList
    }
  }), _c("CollectionEdit", {
    ref: "collectionEditRef",
    on: {
      done: _vm.getList
    }
  }), _c("UploadInvoice", {
    ref: "uploadInvoiceRef",
    on: {
      done: _vm.getList
    }
  }), _c("Logistics", {
    ref: "logisticsRef",
    on: {
      done: _vm.getList
    }
  }), _c("Outbound", {
    ref: "outboundRef",
    attrs: {
      row: _vm.data[_vm.currentIndex]
    },
    on: {
      done: _vm.getList
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };