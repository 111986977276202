export default {
  title: 'MarketSupplementaryIndex',
  data() {
    return {
      module: 'market_supplementary',
      operates: {
        del: true,
        look: false,
        add: true,
        edit: true,
        recycle: {
          autoLink: true
        }
      },
      formAction: '',
      form: {}
    };
  },
  methods: {
    getList() {
      this.$refs['wTable'].getList();
    },
    onEdit(row) {
      this.setForm(row);
      this.formAction = `${this.module}/edit`;
    },
    onAdd() {
      this.setForm({});
      this.formAction = `${this.module}/add`;
    },
    setForm({
      id,
      market_id,
      file
    }) {
      this.form = {
        id: {
          show: false,
          value: id
        },
        ware_id: {
          label: '销售单',
          value: market_id,
          formType: 'select',
          options: '/market/select'
        },
        file: {
          label: '补充协议',
          value: file,
          formType: 'upload',
          attrs: {
            accept: '.pdf',
            tip: '只能上传 pdf，最大20M',
            limit: 1,
            data: {
              type: 'static'
            }
          }
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    getForm() {
      return this.$refs.wDialogForm.$refs.form;
    }
  }
};