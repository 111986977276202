export default {
  name: 'ConfigTrans',
  data() {
    return {
      extActiveName: 'sb',
      authConfig: [],
      notAuthConfig: [],
      activeName: '1'
    };
  },
  mounted() {
    this.getList();
  },
  activated() {
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.$w_fun.post('config/trans', {
        m: this.extActiveName === 'sb' ? 0 : 1
      }).then(response => {
        if (response.data && response.data.length > 0) {
          this.notAuthConfig = response.data[0] || [];
          this.authConfig = response.data[1] || [];
        }
        this.listLoading = false;
      });
    },
    save() {
      this.$w_fun.post('config/saveTrans', {
        value: [this.notAuthConfig, this.authConfig],
        m: this.extActiveName === 'sb' ? 0 : 1
      }).then(response => {
        this.getList();
      });
    },
    handleClick() {
      this.getList();
    }
  }
};